import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Button from "../components/Button"
import Layout from "../components/Layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <section className="my-8 text-center">
        <StaticImage src="../assets/escher-relativity.jpg" alt="Escher, Relativity" />
        <p>Ti sei perso? La pagina richiesta non esiste</p>
        <div className="my-5">
          <Button to="/" text="Torna alla Home" className="py-6" />
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
